const Sem2 = [
    {
        id: 1,
        name: "PYTHON"
    },
    {
        id: 2,
        name: "relational database management system"
    },
    {
        id: 3,
        name: "PHP"
    },
    {
        id: 4,
        name: "Basic Software Engineering"
    },
    {
        id: 5,
        name: "communication Skills II"
    }
]

export default Sem2;