const Sem1 = [
    {
        id: 1,
        name: "Fundamental of programming with C language"
    },
    {
        id: 2,
        name: "fundamentals of computer"
    },
    {
        id: 3,
        name: "fundamental of Web development"
    },
    {
        id: 4,
        name: "Basic Mathematics"
    },
    {
        id: 5,
        name: "communication Skills"
    },
    {
        id: 6,
        name: "Financial accounting"
    }
]

export default Sem1;